import { TFunction } from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import {
  GfppActions,
  TRACKER_HELP_ID,
  statusDesignTabs,
  statusDesignTitleKey,
} from '../../constants/gfpp';
import { configureDesignPanel } from '../../utils/gfpp';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  _,
  flowAPI,
) => {
  const t = flowAPI.translations.t as TFunction;
  widgetBuilder.behavior().set({ removable: false });

  widgetBuilder.gfpp().set('mainAction1', { behavior: 'DEFAULT' });

  widgetBuilder
    .set({
      displayName: t('tracker.page.editor.widget-name.status-container'),
    })
    .gfpp()
    .set('mainAction1', {
      label: t('tracker.page.editor.gfpps.main-action-1.open-prep-board'),
      actionId: GfppActions.KANBAN_ACTION_ID,
    })
    .set('design', { behavior: 'DEFAULT' })
    .set('help', { id: TRACKER_HELP_ID });

  configureDesignPanel(
    widgetBuilder,
    t,
    statusDesignTitleKey,
    statusDesignTabs,
  );
};
