import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { TFunction } from '@wix/yoshi-flow-editor';
import {
  GfppActions,
  TRACKER_HELP_ID,
  globalDesignTabs,
  globalDesignTitleKey,
  headingDesignTabs,
  headingDesignTitleKey,
  methodDesignTabs,
  methodDesignTitleKey,
  statusDesignTabs,
  statusDesignTitleKey,
} from '../../constants/gfpp';
import { configureDesignPanel } from '../../utils/gfpp';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  _,
  flowAPI,
) => {
  const t = flowAPI.translations.t as TFunction;
  widgetBuilder.behavior().set({ removable: false });

  widgetBuilder
    .set({
      displayName: t('tracker.page.editor.widget-name.root'),
      nickname: 'TrackingPageWidget',
    })
    .gfpp()
    .set('mainAction1', {
      label: t('tracker.page.editor.gfpps.main-action-1.open-prep-board'),
      actionId: GfppActions.KANBAN_ACTION_ID,
    })
    .set('design', { behavior: 'DEFAULT' })
    .set('help', { id: TRACKER_HELP_ID });

  configureDesignPanel(
    widgetBuilder,
    t,
    globalDesignTitleKey,
    globalDesignTabs,
  );

  widgetBuilder.configureConnectedComponents(
    'tracker2',
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder
        .set({
          displayName: t('tracker.page.editor.widget-name.status-container'),
        })
        .gfpp()
        .set('mainAction1', {
          label: t('tracker.page.editor.gfpps.main-action-1.open-prep-board'),
          actionId: GfppActions.KANBAN_ACTION_ID,
        })
        .set('design', { behavior: 'DEFAULT' })
        .set('help', { id: TRACKER_HELP_ID });
      configureDesignPanel(
        connectedComponentsBuilder,
        t,
        statusDesignTitleKey,
        statusDesignTabs,
      );
    },
  );

  widgetBuilder.configureConnectedComponents(
    'DeliveryDetailsBox',
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName: t(
          'tracker.page.editor.widget-name.fulfillment-details-container',
        ),
      });
      connectedComponentsBuilder
        .gfpp()
        .set('design', { behavior: 'DEFAULT' })
        .set('mainAction1', {
          label: t('tracker.page.editor.gfpps.main-action-1.open-prep-board'),
          actionId: GfppActions.KANBAN_ACTION_ID,
        })
        .set('help', { id: TRACKER_HELP_ID });

      configureDesignPanel(
        connectedComponentsBuilder,
        t,
        methodDesignTitleKey,
        methodDesignTabs,
      );
    },
  );

  widgetBuilder.configureConnectedComponents(
    'HeaderTracking',
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName: t('tracker.page.editor.widget-name.page-header'),
      });
      connectedComponentsBuilder
        .gfpp()
        .set('design', { behavior: 'DEFAULT' })
        .set('mainAction1', {
          label: t('tracker.page.editor.gfpps.main-action-1.open-prep-board'),
          actionId: GfppActions.KANBAN_ACTION_ID,
        })
        .set('help', { id: TRACKER_HELP_ID });

      configureDesignPanel(
        connectedComponentsBuilder,
        t,
        headingDesignTitleKey,
        headingDesignTabs,
      );
    },
  );
};
