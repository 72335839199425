import { AppWidgetDef } from '../types/types';

type Widgets = 'TrackingPage';

export const widgetsData: {
  [widget in Widgets]: AppWidgetDef;
} = {
  TrackingPage: {
    widgetId: 'f5cdbb6f-bafe-4374-beca-8f0db78bbc2a',
    presetsID: {
      desktop: 'variants-lgphax9b',
      mobile: 'variants-lj16h011',
    },
  },
};
