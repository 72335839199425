import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';
/*
  Place where blocks model is initialized.
  Blocks model allows you to define your Widget Public APIs in a single place.
  It will automatically provide `getExports` for Editor Script and provide typings based on the model for Viewer Script (via model.createController).
*/
export default createBlocksModel({
  widgetName: 'Tracker',
  props: {},
  methods: {
    initDuplexer: {
      description:
        'Set the order Id to be used for subscription on the order status updated',
      params: [{ name: 'orderId', description: "Ecom's order id" }],
      returnsDescription: 'promise of the initial connection',
    },
    setStatus: {
      description: 'Set the current status of the order',
      params: [{ name: 'status' }],
    },
  },
  events: {
    // Here you can define events your widget will subscribe to
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
