import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { GfppActions } from '../constants/gfpp';
import { APP_DEF_IDS, DASHBOARD_URLS } from '@wix/restaurants-consts';

export const registerGfppEvents = async (editorSDK: FlowEditorSDK) => {
  await editorSDK.addEventListener('widgetGfppClicked', (event) => {
    const { id } = event.detail;

    switch (id) {
      case GfppActions.KANBAN_ACTION_ID:
        editorSDK.editor.openDashboardPanel(APP_DEF_IDS.orders, {
          url: DASHBOARD_URLS.prepBoardUrl,
          closeOtherPanels: false,
        });
        break;

      default:
        break;
    }
  });
  await editorSDK.addEventListener('componentGfppClicked', (event) => {
    const { id } = event.detail;

    switch (id) {
      case GfppActions.KANBAN_ACTION_ID:
        editorSDK.editor.openDashboardPanel(APP_DEF_IDS.orders, {
          url: DASHBOARD_URLS.prepBoardUrl,
          closeOtherPanels: false,
        });
        break;

      default:
        break;
    }
  });
};
