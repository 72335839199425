import { AppPageDef } from '../types/types';

type Pages = 'Tracker';

export const pagesData: {
  [page in Pages]: AppPageDef;
} = {
  Tracker: {
    titleKey: 'tracker.editor.page.title',
    tpaPageId: 'tracker',
    pageUriSEO: 'tracker-page',
  },
};
