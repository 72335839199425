import { TabConfig } from '../types/gfpp';

export const TRACKER_HELP_ID = 'c2aed081-dc8b-441c-a83a-fe0c21a5f17e';
export enum GfppActions {
  KANBAN_ACTION_ID = 'kanbanActionId',
}
export const globalDesignTitleKey =
  'tracker.page.editor.design-panel.global.panel-title';
export const globalDesignTabs: TabConfig[] = [
  {
    labelKey: 'tracker.page.editor.design-panel.global.labels.page-title',
    selectors: ['Title'],
  },
  {
    labelKey:
      'tracker.page.editor.design-panel.global.labels.order-status-title',
    selectors: ['StatusTitle'],
  },
  {
    labelKey: 'tracker.page.editor.design-panel.global.labels.subtitles',
    selectors: ['DeliveryDetailsTitle'],
  },
  {
    labelKey:
      'tracker.page.editor.design-panel.global.labels.fulfillment-details-field-titles',
    selectors: ['text25'],
  },
  {
    labelKey: 'tracker.page.editor.design-panel.global.labels.body-texts',
    selectors: [
      'orderNumberlabel',
      'OrderNumber',
      'text24',
      'StatusDescription',
      'StatusLabel',
    ],
  },
  {
    labelKey: 'tracker.page.editor.design-panel.global.labels.divider',
    selectors: ['line4'],
  },
  {
    labelKey: 'tracker.page.editor.design-panel.global.labels.global-widget',
    selectors: ['TrackerPageBox'],
  },
];
export const headingDesignTitleKey =
  'tracker.page.editor.design-panel.heading.panel-title';
export const headingDesignTabs: TabConfig[] = [
  {
    labelKey: 'tracker.page.editor.design-panel.heading.labels.title',
    selectors: ['Title'],
  },
  {
    labelKey: 'tracker.page.editor.design-panel.heading.labels.description',
    selectors: ['orderNumberlabel', 'OrderNumber'],
  },
];
export const statusDesignTitleKey =
  'tracker.page.editor.design-panel.status.panel-title';
export const statusDesignTabs: TabConfig[] = [
  {
    labelKey: 'tracker.page.editor.design-panel.status.labels.box-title',
    selectors: ['StatusLabel'],
  },
  {
    labelKey: 'tracker.page.editor.design-panel.status.labels.status-title',
    selectors: ['StatusTitle'],
  },
  {
    labelKey: 'tracker.page.editor.design-panel.status.labels.texts',
    selectors: ['StatusDescription'],
  },
  {
    labelKey: 'tracker.page.editor.design-panel.status.labels.status-box',
    selectors: ['StatusBox'],
  },
];
export const methodDesignTitleKey =
  'tracker.page.editor.design-panel.fulfillment-details.panel-title';
export const methodDesignTabs: TabConfig[] = [
  {
    labelKey:
      'tracker.page.editor.design-panel.fulfillment-details.labels.subtitles',
    selectors: ['DeliveryDetailsTitle'],
  },
  {
    labelKey:
      'tracker.page.editor.design-panel.fulfillment-details.labels.field-titles',
    selectors: ['text25'],
  },
  {
    labelKey:
      'tracker.page.editor.design-panel.fulfillment-details.labels.field-texts',
    selectors: ['text24'],
  },
  {
    labelKey:
      'tracker.page.editor.design-panel.fulfillment-details.labels.divider',
    selectors: ['line4'],
  },
  {
    labelKey:
      'tracker.page.editor.design-panel.fulfillment-details.labels.fulfillment-details-box',
    selectors: ['DeliveryDetailsBox'],
  },
];
