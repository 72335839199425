import {
  ComponentRef,
  EditorSDK,
  PageRef,
  WidgetInstallationType,
} from '@wix/platform-editor-sdk';
import { AppPageDef, AppWidgetDef } from '../types/types';
import { APP_DEF_IDS } from '../constants';
import { TFunction } from '@wix/yoshi-flow-editor';
import { createSectionDefinition } from './createSectionDef';
import { createWidgetDefinition } from './createWidgetDef';

const getResponsiveComponents = (appDefId: string, widget: AppWidgetDef) => [
  createSectionDefinition([
    createWidgetDefinition({
      appDefinitionId: appDefId,
      widgetId: widget.widgetId,
      desktopPresetId: widget.presetsID.desktop,
      mobilePresetId: widget.presetsID.mobile,
    }),
  ]),
];

const responsiveBreakpoints = {
  type: 'BreakpointsData' as const,
  id: 'breakpoints-k3sr3k4j',
  values: [
    {
      type: 'BreakpointRange' as const,
      id: 'breakpoints-kc1s7zda1',
      min: 320,
      max: 1000,
    },
    {
      type: 'BreakpointRange' as const,
      id: 'breakpoints-kc1s7zda',
      min: 320,
      max: 750,
    },
  ],
};

const getResponsiveWidgetConfig = (
  isResponsive: boolean,
  appDefId: string,
  widget: AppWidgetDef,
) => ({
  components: isResponsive ? getResponsiveComponents(appDefId, widget) : [],
  breakpoints: isResponsive ? responsiveBreakpoints : undefined,
});

export const addNewPage = async (
  editorSDK: EditorSDK,
  appDefId: string,
  t: TFunction,
  pageDef: AppPageDef,
  isResponsive: boolean,
  widget: AppWidgetDef,
  shouldAddMenuItem = false,
  shouldNavigateToPage = false,
): Promise<PageRef> => {
  const { titleKey, tpaPageId, pageUriSEO } = pageDef;
  const { breakpoints, components } = getResponsiveWidgetConfig(
    isResponsive,
    appDefId,
    widget,
  );
  const pageRef = await editorSDK.pages.add('', {
    title: t(titleKey),
    definition: {
      type: 'Page',
      components,
      breakpoints,
      data: {
        indexable: false,
        managingAppDefId: APP_DEF_IDS.parentApp,
        appDefinitionId: appDefId,
        tpaPageId,
        pageUriSEO,
      },
    },
    shouldAddMenuItem,
    shouldNavigateToPage,
  });

  return pageRef;
};

export const addWidgetToPage = async (
  editorSDK: EditorSDK,
  containerRef: ComponentRef,
  widgetDef: AppWidgetDef,
) => {
  const { widgetId, presetsID } = widgetDef;

  const componentRef = await editorSDK.application.appStudioWidgets.addWidget(
    '',
    {
      widgetId,
      installationType: WidgetInstallationType.Closed,
      containerRef,
      layout: {
        x: 0,
        y: 0,
        fixedPosition: false,
        width: 980,
        height: 90,
        scale: 1,
        rotationInDegrees: 0,
      },
      layouts: {
        componentLayout: {
          minHeight: {
            type: 'px',
            value: 550,
          },
          hidden: false,
          height: {
            type: 'auto',
          },
          type: 'ComponentLayout',
          width: {
            type: 'auto',
          },
        },
        itemLayout: {
          id: '',
          alignSelf: 'center',
          gridArea: {
            rowStart: 1,
            rowEnd: 2,
            columnStart: 1,
            columnEnd: 2,
          },
          justifySelf: 'center',
          type: 'GridItemLayout',
        },
      },
      scopedPresets: {
        desktop: {
          style: presetsID.desktop,
          layout: presetsID.desktop,
        },
        mobile: {
          style: presetsID.mobile,
          layout: presetsID.mobile,
        },
      },
    },
  );

  return componentRef;
};
