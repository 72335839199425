import { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';
import { addNewPage, addWidgetToPage } from './utils/installationUtils';
import { pagesData } from './constants/pages';
import { widgetsData } from './constants/widgets';
import { TFunction } from '@wix/yoshi-flow-editor';

export const handleInstall = async (
  editorSDK: EditorSDK,
  appDefId: string,
  t: TFunction,
  isResponsive: boolean,
) => {
  const trackerPageRef = await addNewPage(
    editorSDK,
    appDefId,
    t,
    pagesData.Tracker,
    isResponsive,
    widgetsData.TrackingPage,
  );

  if (!isResponsive) {
    const pageChildren = await editorSDK.components.getChildren('', {
      componentRef: trackerPageRef as ComponentRef,
    });

    const containerRef = pageChildren[0];

    await addWidgetToPage(
      editorSDK,
      containerRef ?? trackerPageRef,
      widgetsData.TrackingPage,
    );
  }
};
